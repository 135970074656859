import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {LoginHelperService} from "../shared/services/login-helper.service";
import {RedirectionRoutesConfigurationService} from "../shared/services/redirection-routes-configuration.service";
import {isPlatformServer} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService {

    constructor(
        private loginHelperService: LoginHelperService,
        private router: Router,
        private redirectionRoutesConfigurationService: RedirectionRoutesConfigurationService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        if (!this.loginHelperService.isUserLoggedIn()) {
            this.loginHelperService.setReturnPage(state.url);
            const redirectionRoute = this.redirectionRoutesConfigurationService.getRedirectionRoute(state.url);
            this.router.navigateByUrl(redirectionRoute);
            return false;
        }
        return true;
    }

}
